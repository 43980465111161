import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2"

const swalConfirm = async (options?: SweetAlertOptions): Promise<SweetAlertResult> => {
  const result = await Swal.fire({
    text: "Emin misiniz",
    width: 250,
    padding: "1em",
    showCancelButton: true,
    buttonsStyling: false,
    confirmButtonText: "Evet",
    cancelButtonText: "Hayır",
    customClass: {
      confirmButton: "el-button el-button--small",
      cancelButton: "el-button el-button--danger el-button--small",
    },
    ...options,
  })
  return result
}

export default swalConfirm
